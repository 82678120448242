<template>
  <div class="UserSmartMenu" :class="{ prevent: $loading }">
    <div class="UserSmartMenuContent">
      <Carrousel :settings="{ items: 4, slidesToScroll: 4 }">
        <CircleButton
          v-for="(item, index) in menu"
          :label="item.label"
          :icon="item.icon"
          :size="iconSize"
          @click="button(item.method || 'navigate', item.path)"
          :class="`${mkLink(item.path)}`"
          :key="index"
        />
      </Carrousel>
    </div>
  </div>
</template>

<script>
import UserSmartMenu from "@/components/user/UserSmartMenu.js";
export default {
  mixins: [UserSmartMenu],
  props: ["modal"],
  data: function() {
    return { iconSize: "44px" };
  },
  methods: {
    navigate: function(path) {
      this.modal.close(() => {
        this.$router.push(path).catch((err) => {});
      });
    },
    button: function(name, data) {
      this[name](data);
    },
    laguage: function() {
      this.modal.close(() => {
        this.languageSelector();
      });
    },
  },
};
</script>

<style lang="scss">
.UserSmartMenu {
  position: relative;
  user-select: none;

  .CircleButton {
    position: relative;
    background-color: rgba($color: $alto, $alpha: 0);
    overflow: hidden;
    border-radius: $mpadding/1.5;
  }

  .CircleButton.router-link-exact-active * {
    color: $primary_color;
  }

  .CircleButton .CircleButtonLabel {
    font-size: 80%;
    padding: 0 $mpadding/2 $mpadding $mpadding/2;
    @include ellipsis();
  }

  .CircleButton .CircleButtonFigure {
    background-color: rgba($color: #00000000, $alpha: 0);
    margin: $mpadding/2 auto auto auto;
  }

  .CircleButton * {
    pointer-events: none;
  }

  .CircleButton:active {
    opacity: 0.5;
    * {
      color: $primary_color;
    }
  }
}
</style>
