export default {
  computed: {
    menu: function() {
      return [
        { icon: "house", label: this.$locale["home_label"], path: this.$links["home"] },
        { icon: "bookmark", label: this.$locale["favorites"], path: this.$links["my-favorites"] },
        { icon: "heart", label: this.$locale["subscriptions"], path: this.$links["my-subscriptions"] },
        { icon: "coins", label: this.$locale["my_credits"], path: this.$links["my-credits"] },
        { icon: "bell", label: this.$locale["notifications"], path: this.$links["my-notifications"] },
        { icon: "comment", label: this.$locale["messages"], path: this.$links["my-messages"] },
        { icon: "sliders", label: this.$locale["settings"], path: this.$links["my-settings"] },
        { icon: "globe", label: this.$locale["laguage"], method: "laguage" },
      ];
    },
  },
};
